import React from 'react'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import Form from '../components/form'
import Seo from '../components/seo'
import { getImage } from "gatsby-plugin-image"
import Nav from '../components/nav'

const ContactPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
	return (
    <div>
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <Nav />
      <div className="w-full pt-12 pb-12">
        <div className="flex justify-around items-center text-center">
          <div className="w-10/12 sm:w-8/12 lg:w-8/12 xl:w-6/12 font-verdana font-bold text-black text-6xl sm:text-6xl lg:text-5xl leading-tight">
            <h2>{entry.frontmatter.heading}</h2>
          </div>
        </div>
      </div>
      <div className="w-full pb-12">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex justify-between gap-24">
            <div className="w-full sm:w-8/12">
              <div className="max-w-none font-verdana prose prose-md lg:prose-lg">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
              <Form />
            </div>
            <div className="w-full sm:w-4/12 pt-12 sm:pt-0">
              <figure className="mb-12">
                <iframe width="100%" height="360" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1596.3873676019464!2d174.76155905658257!3d-36.84787067993876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47faa2772c25%3A0xa76eddce558c97a8!2sWarner%20Displays!5e0!3m2!1sen!2snz!4v1638668529057!5m2!1sen!2snz" allowFullScreen={false} loading="lazy"></iframe>
              </figure>
              {entry.frontmatter.side_heading !== null &&
              <div className="font-verdana text-blue font-bold text-2xl">
                <h3>{entry.frontmatter.side_heading}</h3>
              </div>
              }
              {entry.frontmatter.side_text !== null && 
              <div className="font-verdana prose prose-md lg:prose-lg mt-6">
                <ul>
                {entry.frontmatter.side_text.map((entry, i) => {
                  return (
                    <li key={`side_text_${i}`}>{entry.text}</li>
                  )
                })}
                </ul>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-24 relative">
        <div className="absolute -inset-0">
          <img src="/images/uploads/shutterstock_1083903761.jpg" alt="" className="object-cover object-top w-full h-full block" /> 
        </div>
        <div className="w-10/12 mx-auto max-w-screen-2xl relative">
          <div className="flex justify-around items-center text-center">
            <div className="w-10/12 sm:w-8/12 lg:w-8/12 xl:w-8/12">
              <div className="max-w-none font-verdana prose prose-md lg:prose-2xl text-center">
                <p>{entry.frontmatter.quote} <strong>&mdash; {entry.frontmatter.author}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default ContactPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        side_heading
        side_text {
          text
        }
        quote
        author
			}
			fields {
				slug
			}
      html
		}
	}
`